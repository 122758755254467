import Button from './atoms/Button';
import { BusinessDTO } from '../models/business';
import EventDTO from '../models/event';

interface BizEventSummaryProps {
  title: string;
  subtitle: string;
  mainButtonText?: string;
  itemButtonText?: string;
  mainClick?: () => void;
  itemClick?: (item: number, index: number) => void;
  data: BusinessDTO[] | EventDTO[];
}

const BizEventSummary = ({
  title, subtitle, mainButtonText, itemButtonText, mainClick, itemClick, data,
}: BizEventSummaryProps): JSX.Element => (
    <div className="flex flex-col gap-6 bg-white border border-gray-200 rounded-lg px-6 py-4">
      <div className="flex flex-col gap-1">
        <div className="grid grid-cols-1 md:grid-cols-[1fr_auto] gap-4">
          <h1 className="text-[30px] font-medium order-1">{title}</h1>
          {mainButtonText && (
            <div className="order-3 md:order-2">
              <Button onClick={mainClick}>{mainButtonText}</Button>
            </div>
          )}
          <p className="text-[16px] order-2 md:order-3 md:col-span-2">{subtitle}</p>
        </div>
      </div>
      <div className="flex flex-col">
        {data.map((item, index) => (
          <div
            key={item.id}
            className={`flex flex-row items-center p-4 border-gray-200 ${index === 0 ? 'border-y' : 'border-b'} md:cursor-default cursor-pointer`}
            onClick={() => (itemClick && window.innerWidth < 768 ? itemClick(item.id, index) : undefined)}
          >
            <p>{item.name}</p>
            <div className="ml-auto hidden md:block">
              {itemButtonText && (
                <Button onClick={() => itemClick?.(item.id, index)} variant='secondary'>{itemButtonText}</Button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
);

export default BizEventSummary;
