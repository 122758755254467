/* eslint-disable no-nested-ternary */
import { FaLink } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import OrdersTableSkeleton from '../components/OrdersTableSkeleton';
import IconInfoCard from '../components/atoms/IconInfoCard';
import CustomModal from '../components/atoms/CustomModal';
import useGetParams from '../hooks/useGetParams';
import { useGetAllReferralsQuery } from '../store';
import ReferralsTable from '../components/ReferralsTable';
import CreateEditReferral from '../components/CreateEditReferral';
import AdminSectionTitle from '../components/AdminSectionTitle';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';

const AdminReferralsPage: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { eventId, businessId } = useGetParams();
  const { event } = useGetSelectedEvent();
  const { data: referrals, isFetching } = useGetAllReferralsQuery({
    businessId,
    eventId,
  });

  const handleSucessAddReferral = () => {
    setShowModal(false);
    toast.success('Referido creado correctamente');
  };

  const handleAddReferral = () => {
    setShowModal(true);
  };

  useEffect(() => {
    console.log({ referrals });
  }, []);

  return (
    <div className="flex flex-col gap-8 my-6">
      <AdminSectionTitle title="Eventos" subtitle={`${event?.name} - Referidos`} />
      <div>
        {isFetching && <OrdersTableSkeleton />}{' '}
        {referrals && referrals.length > 0 && (
          <ReferralsTable referrals={referrals} eventId={+eventId} businessId={+businessId} button={{
            label: 'Crear referido',
            onClick: handleAddReferral,
            variant: 'secondary',
            image: '/link.svg',
          }} />
        )}
        {referrals && referrals.length === 0 && (
          <IconInfoCard
            title="Aún no hay referidos creados"
            info="Aquí aparecerán todos los referidos que crees en el sistema"
            icon={FaLink}
            iconColor="text-indigo-600"
          />
        )}
      </div>
      <CustomModal
        onRequestClose={() => setShowModal(false)}
        isOpen={showModal}
        actionBar={<div></div>}
        hideCancelButton={true}
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <h1 className="text-xl font-medium">Crear referido</h1>
            <p className="text-gray-400 text-sm">
              Podrás realizar las ediciones que necesites en este referido
            </p>

          </div>
          <CreateEditReferral onSuccess={handleSucessAddReferral} onCancel={() => setShowModal(false)} />
        </div>
      </CustomModal>
    </div>

  );
};

export default AdminReferralsPage;
