import React, { useState } from 'react';

interface ButtonGroupProps {
  buttons: string[];
  defaultButton?: string;
  onButtonChange: (buttonId: string) => void;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ buttons, defaultButton, onButtonChange }) => {
  const [activeButtonId, setActiveButtonId] = useState(defaultButton);
  const handleButtonChange = (button: string) => {
    onButtonChange(button);
    setActiveButtonId(button);
  };

  return (
    <div className="flex border border-gray-300 rounded-lg shadow md:w-1/2 lg:w-1/3 overflow-hidden">
      {buttons.map((button, index) => (
          <button
            key={index}
            onClick={() => handleButtonChange(button)}
            className={`flex text-sm w-full py-2 px-3 justify-center border-r border-gray-300 items-center gap-2 flex-1 whitespace-nowrap ${activeButtonId === button
              ? 'text-gray-800 font-medium bg-gray-50'
              : 'text-gray-500 hover:text-gray-700 bg-white'
            } ${index === 0 ? 'rounded-tl-[6px] rounded-bl-[6px]' : ''} ${index === buttons.length - 1 ? 'rounded-tr-[6px] rounded-br-[6px] border-r-0' : ''}`}
          >
            {button}
          </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
