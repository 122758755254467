import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Slider from 'react-slick';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick-theme.css';
import LandingEventCard from '../../LandingEventCard';
import EventDTO from '../../../models/event';
import useNavigateWithQuery from '../../../hooks/useNavigateWithQuery';

interface Props {
  cards: EventDTO[];
}

const CarrouselCards: React.FC<Props> = ({ cards }) => {
  const navigate = useNavigateWithQuery();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.04,
        },
      },
    ],
  };

  const handleEventSelect = (eventId: number) => {
    navigate(`/events/${eventId}`);
  };
  return (
    <div>
      <Slider {...settings}>
        {cards.map((card, index) => (
          <div key={index} className='w-[90%] lg:w-full relative p-2 lg:p-4'>
           <LandingEventCard event={card} onSelect={handleEventSelect}/>
          </div>))}
      </Slider>
    </div>
  );
};

export default CarrouselCards;
