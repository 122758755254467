import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetSellerSummaryQuery } from '../store';
import FilterTable, { BaseData } from './Table/Table';
import { setSellerDetails } from '../store/slices/orders';
import { SellerSummaryDTO } from '../models/order';
import Button from './atoms/Button';
import { formatCurrency } from '../utils/shared';

interface Props {
  businessId: number;
  eventId: number;
  button?: {
    label: string;
    image: string;
    onClick: () => void;
    variant: 'primary' | 'secondary' | 'tertiary';
  };
}

interface TableSellerItem extends BaseData {
  id: number;
  username: string;
  total_items_count: number;
  total_amount: number | JSX.Element;
  commission: string | JSX.Element;
  expenses: number | JSX.Element;
  balance: number | JSX.Element;
  total_commision: number | JSX.Element;
}

const SellersTable: React.FC<Props> = ({ businessId, eventId, button }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: sellerSummary, refetch: getSellerSummary } = useGetSellerSummaryQuery({ businessId, eventId });

  useEffect(() => {
    getSellerSummary();
  }, [getSellerSummary]);

  const headCells = [
    {
      id: 'username',
      label: 'Usuario',
    },
    {
      id: 'total_items_count',
      label: 'Cantidad de ventas',
    },
    {
      id: 'total_amount',
      label: 'Monto total',
    },
    {
      id: 'commission',
      label: 'Comisión',
    },
    {
      id: 'total_commision',
      label: 'Comisión total',
    },
    {
      id: 'expenses',
      label: 'Gastos',
    },

    {
      id: 'balance',
      label: 'Saldo',
    },
  ];

  const tableRows: TableSellerItem[] = sellerSummary?.map((user) => ({
    id: user?.seller_id || 0,
    username: user?.seller_name || '',
    commission: user?.user?.current_event?.seller_comission_rate
      ? <div className='whitespace-nowrap'>{user.user.current_event.seller_comission_rate * 100}%</div>
      : '-',
    total_items_count: user?.total_items_count || 0,
    total_amount: user?.total_amount ? <div className='whitespace-nowrap'>{formatCurrency(user.total_amount)}</div> : 0,
    total_commision: user?.total_comission_amount ? <div className='whitespace-nowrap'>{formatCurrency(user.total_comission_amount)}</div> : 0,
    expenses: user?.transactions_summary?.total_amount ? <div className='whitespace-nowrap'>{formatCurrency(user.transactions_summary.total_amount)}</div> : 0,
    balance: user?.balance ? <div className='whitespace-nowrap'>{formatCurrency(user.balance)}</div> : 0,

  })) || [];

  const handleViewDetails = (row: TableSellerItem) => {
    dispatch(setSellerDetails(sellerSummary?.find((user) => user.seller_id === row.id) as SellerSummaryDTO));
    navigate('details');
  };

  const actions = (row: TableSellerItem) => (
    <div className='w-fit mx-auto hover:cursor-pointer'>
      <img src="/eye.svg" alt="eye" onClick={() => handleViewDetails(row)} />
    </div>
  );

  const customButton = button && <Button onClick={button.onClick} variant={button.variant}>
    <div className='flex flex-row gap-2 items-center'>
      <img src={button.image} alt={button.label} className='min-w-[20px] min-h-[20px] w-5 h-5' />
      <p className='whitespace-nowrap hidden md:block'>{button.label}</p>
    </div>
  </Button>;

  return (
    <div>
      {tableRows.length > 0 ? (
        <FilterTable
          rows={tableRows}
          headCells={headCells}
          actions={actions}
          customButtons={customButton ? [customButton] : []}
          pagination={{
            pageSize: 10,
            enabled: true,
          }}
          searchEnabled={true}
        />
      ) : (
        <div className='flex flex-col gap-2 items-center justify-center h-full border border-gray-200 rounded-lg bg-white shadow-sm p-4'>
          <h1 className='text-lg font-medium text-center'>No hay vendedores actualmente</h1>
        </div>
      )}
    </div>

  );
};

export default SellersTable;
