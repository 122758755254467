import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import Input from './atoms/Input';
import Button from './atoms/Button';
import {
  useAddEventTransactionMutation,
  useUpdateEventTransactionMutation,
  useGetEventTransactionsQuery,
} from '../store';
import { toastSucces } from '../utils/toasts';

interface Props {
  businessId: number;
  eventId: number;
  sellerId: number;
  onSuccess: () => void;
  editTransaction?: boolean;
  transactionId?: number;
}

interface FormData {
  amount: number;
  description: string;
}

const validationSchema = yup.object<FormData>().shape({
  amount: yup
    .number()
    .required('El monto es requerido')
    .min(0, 'El monto debe ser mayor a 0'),
  description: yup
    .string()
    .required('La descripción es requerida'),
});

const AddUserExpense: React.FC<Props> = ({
  businessId, eventId, sellerId, onSuccess, editTransaction, transactionId,
}) => {
  const form = useForm<FormData>({
    resolver: yupResolver<FormData>(validationSchema),
    defaultValues: editTransaction ? {
      amount: 0,
      description: '',
    } : undefined,
  });
  const {
    control, handleSubmit, formState, reset,
  } = form;
  const [addEventTransaction, { isLoading: isAddLoading }] = useAddEventTransactionMutation();
  const [updateEventTransaction, { isLoading: isUpdateLoading }] = useUpdateEventTransactionMutation();
  const { data: transactions } = useGetEventTransactionsQuery({ businessId, eventId }, {
    skip: !businessId || !eventId,
  });

  useEffect(() => {
    if (editTransaction && transactionId && transactions) {
      const transaction = transactions.data?.find((t: { id: number; amount: number; description: string }) => t.id === transactionId);
      if (transaction) {
        reset({
          amount: transaction.amount,
          description: transaction.description,
        });
      }
    }
  }, [editTransaction, transactionId, transactions, reset]);

  const handleAddExpense = async (data: FormData) => {
    try {
      const result = await addEventTransaction({
        businessId,
        eventId,
        transactionData: {
          user_id: sellerId,
          is_seller_expense: true,
          amount: data.amount,
          type: 'SELLER_EXPENSE',
          description: data.description,
        },
      }).unwrap();

      if (result) {
        toastSucces('Gasto agregado correctamente');
        onSuccess();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleEditExpense = async (data: FormData) => {
    try {
      const result = await updateEventTransaction({
        businessId,
        eventId,
        transactionId: transactionId as number,
        transactionData: data,
      }).unwrap();

      if (result) {
        toastSucces('Gasto editado correctamente');
        onSuccess();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <form
        className="w-full h-full"
        onSubmit={handleSubmit(editTransaction ? handleEditExpense : handleAddExpense)}
      >
      <div className="mb-5">
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input
                label="Observación"
                labelAbove={true}
                id="description"
                {...field}
                errMsg={formState.errors.description?.message}
              />
            )}
          />
        </div>
        <div className="mb-5">
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <Input
                label="Monto"
                labelAbove={true}
                id="amount"
                type="number"
                {...field}
                errMsg={formState.errors.amount?.message}
              />
            )}
          />
        </div>
        <div className="flex justify-center align-items-center whitespace-nowrap">
          <div className="flex gap-2">
            <Button
              className="w-full"
              variant="secondary"
              type="button"
              onClick={onSuccess}
            >
              Cancelar
            </Button>
            <Button
              className="w-full"
              variant="primary"
              loading={editTransaction ? isUpdateLoading : isAddLoading}
              type="submit"
            >
              {editTransaction ? 'Editar gasto' : 'Agregar gasto'}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddUserExpense;
