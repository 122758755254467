/* eslint-disable no-nested-ternary */
import { AiOutlinePercentage } from 'react-icons/ai';
import { useState } from 'react';
import OrdersTableSkeleton from './OrdersTableSkeleton';
import { useGetAllCouponsQuery } from '../store';
import IconInfoCard from './atoms/IconInfoCard';
import CustomModal from './atoms/CustomModal';
import CouponsTable from './CouponsTable';
import CreateEditCoupon from './CreateEditCoupon';
import useGetParams from '../hooks/useGetParams';
import AdminSectionTitle from './AdminSectionTitle';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';

const CRUDCoupon: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { event } = useGetSelectedEvent();
  const { eventId, businessId } = useGetParams();

  const { data: coupons, isFetching } = useGetAllCouponsQuery({
    businessId,
    eventId: +eventId,
  });

  const handleSucessAddCoupon = () => {
    setShowModal(false);
  };

  const handleAddCoupon = () => {
    setShowModal(true);
  };

  return (
    <div className="flex flex-col gap-8 my-6">
      <AdminSectionTitle title="Eventos" subtitle={`${event?.name} - Cupones`} />
      <div>
        {isFetching && <OrdersTableSkeleton />}{' '}
        {coupons && coupons.length > 0 && (
          <CouponsTable coupons={coupons} eventId={+eventId} businessId={+businessId} button={{
            label: 'Crear nuevo cupón',
            onClick: handleAddCoupon,
            variant: 'secondary',
            image: '/sale-01.svg',
          }} />
        )}
        {coupons && coupons.length === 0 && (
          <IconInfoCard
            title="Aún no hay cupones creados"
            info="Aquí aparecerán todos los cupones que crees en el sistema"
            icon={AiOutlinePercentage}
            iconColor="text-indigo-600"
          />
        )}
      </div>
      <CustomModal
        onRequestClose={() => setShowModal(false)}
        isOpen={showModal}
        hideCancelButton={true}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h1 className="text-xl font-medium">Crear cupón</h1>
            <p className="text-gray-400 text-sm">

              Podrás realizar las ediciones que necesites en este cupón
            </p>
          </div>
          <CreateEditCoupon onSuccess={handleSucessAddCoupon} onCancel={() => setShowModal(false)} />
        </div>
      </CustomModal>
    </div>

  );
};

export default CRUDCoupon;
