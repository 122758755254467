import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  RootState, useGetAllBusinessesQuery, useGetUserBusinessesQuery, useGetAllUserEventsQuery,
} from '../store';
import BizEventSummary from '../components/BizEventSummary';
import { setSelectedEvent } from '../store/slices/events';
import BizEventSummarySkeleton from '../components/BizEventSummarySkeleton';

const Dashboard = () => {
  const userData = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const {
    data: allBusinesses,
    isLoading: isAllBusinessesLoading,
  } = useGetAllBusinessesQuery(
    { query: { active: true } },
    {
      skip: !userData || userData.type !== 'ROOT',
    },
  );
  const {
    data: userBusinesses,
    isLoading: isUserBusinessesLoading,
  } = useGetUserBusinessesQuery(
    { userId: userData.id },
    { skip: !userData || !userData.id || userData.type === 'ROOT' },
  );
  const navigate = useNavigate();
  const {
    data: events,
    isLoading: isEventsLoading,
  } = useGetAllUserEventsQuery({ userId: userData.id });

  const activeBusinesses = userData.type === 'ROOT'
    ? allBusinesses?.filter((business) => business.active === true)
    : userBusinesses?.filter((business) => business.active === true);

  const activeEvents = events?.filter((event) => event.active === true);

  const onGoToEventClick = (eventIndex: number) => {
    if (!activeEvents || eventIndex >= activeEvents.length) {
      return;
    }
    const event = activeEvents[eventIndex];
    if (event) {
      dispatch(setSelectedEvent(event));
      navigate(`/admin/${event.business_id}/events/${event.id}`);
    }
  };

  return (
    <div className="flex flex-col gap-8 my-6">
      <div className="flex flex-row gap-4 items-center">
        <p className="text-[30px] font-medium">Hola {userData.username}!</p>
        <img src="/admin-dashboard-1.svg" alt="" />
        <img src="/admin-dashboard-2.svg" alt="" />
      </div>
      {/* <ButtonGroup buttons={['Hoy', 'Últimos 7 días', 'Últimos 30 días']} defaultButton="Hoy" onButtonChange={setActiveButton} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <TitleNumberCard title="Total de pedidos" number={formatCurrency(100, 'no-decimals')} buttonText="Ver todos" buttonLink="/orders" />
        <TitleNumberCard title="Total de pedidos" number={formatCurrency(100, 'no-decimals')} buttonText="Ver todos" buttonLink="/orders" />
        <TitleNumberCard title="Total de pedidos" number={formatCurrency(100, 'no-decimals')} buttonText="Ver todos" buttonLink="/orders" />
      </div> */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {isEventsLoading ? (
          <BizEventSummarySkeleton />
        ) : (
          <BizEventSummary
            title="Eventos"
            subtitle="Últimos eventos cargados"
            itemButtonText="Ver evento"
            data={activeEvents ?? []}
            itemClick={(_, index) => onGoToEventClick(index)}
          />
        )}
        {(isAllBusinessesLoading || isUserBusinessesLoading) ? (
          <BizEventSummarySkeleton />
        ) : (
          <BizEventSummary
            title="Negocios"
            subtitle="Negocios activos"
            mainButtonText='Ir a Negocios'
            itemButtonText='Ver negocio'
            data={activeBusinesses ?? []}
            mainClick={() => navigate('/admin/businesses')}
            itemClick={(item) => navigate(`/admin/${item}/events`)}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
