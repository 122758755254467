import { Fragment, useState } from 'react';
import MenuDropdown from './atoms/MenuDropdown';
import CustomModal from './atoms/CustomModal';
import { useDeleteTicketMutation } from '../store';
import Button from './atoms/Button';
import TicketDTO from '../models/ticket';
import CreateTicket from './CreateTicket';
import FilterTable, { BaseData } from './Table/Table';
import { formatCurrency } from '../utils/shared';

interface Props {
  tickets: TicketDTO[];
  eventId: number;
  businessId: number;
  button?: {
    label: string;
    image: string;
    onClick: () => void;
    variant: 'primary' | 'secondary' | 'tertiary';
  };
}

interface TableTicket extends BaseData {
  id: number;
  name: string;
  amount_taken: string;
  price: JSX.Element;
}

const TicketsTable: React.FC<Props> = ({
  tickets,
  eventId,
  businessId,
  button,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);
  const [deleteTicket] = useDeleteTicketMutation();
  const handleConfirmDeleteTicket = (ticket: TicketDTO) => {
    deleteTicket({
      businessId: businessId || 0,
      eventId: +eventId,
      ticketId: ticket.id,
    });
    setShowModal(false);
  };

  const handleClose = () => setShowModal(false);

  const handleDeleteTicket = (ticket: TicketDTO) => {
    setModalBody(
      <div className="flex flex-col gap-4">
        {ticket.amount_taken === 0 ? (
          <div>
            <p className="text-xl font-medium">¿Deseas eliminar esta entrada?</p>
            <p className="text-sm text-gray-400">
              Una vez eliminada desaparecerá del listado.
          </p>
          <div className="flex flex-row gap-2">
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={() => handleConfirmDeleteTicket(ticket)}
            >
              Eliminar
            </Button>
          </div>
        </div>
        ) : (
          <div>
            <p className="text-xl font-medium">Esta entrada no se puede eliminar</p>
            <p className="text-sm text-gray-400 mb-4">
              Esta entrada no se puede eliminar porque ya tiene ventas.
            </p>
            <Button variant="secondary" onClick={handleClose}>
              Aceptar
            </Button>
          </div>
        )}
      </div>,
    );

    setShowModal(true);
  };

  const handleEditTicket = (ticket: TicketDTO) => {
    setModalBody(
      <Fragment>
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-2xl font-medium">Editar entrada</h1>
        </div>
        <CreateTicket ticket={ticket} onSuccess={handleClose} onCancel={handleClose} />
      </Fragment>,
    );
    setShowModal(true);
  };

  const customButton = button && (
    <Button onClick={button.onClick} variant={button.variant}>
      <div className="flex items-center justify-center gap-2 p-2">
        <img
          src={button.image}
          alt={button.label}
          className={'min-w-[20px] min-h-[20px] w-5 h-5'}
        />
        <p className="hidden min-[1150px]:block whitespace-nowrap">
          {button.label}
        </p>
      </div>
    </Button>
  );

  const dropDownOptions = [
    {
      label: 'Editar',
      onClick: handleEditTicket,
    },
    {
      label: 'Eliminar',
      onClick: handleDeleteTicket,
    },
  ];

  const config = [
    {
      label: 'Nombre',
      id: 'name',
    },
    {
      label: 'Cantidad',
      id: 'amount_taken',
    },
    {
      label: 'Precio',
      id: 'price',
    },
  ];

  const rows: TableTicket[] = tickets.map((ticket) => ({
    id: ticket.id,
    name: ticket.name,
    amount_taken: `${ticket.amount_taken} / ${ticket.total_amount}`,
    price: <div className='whitespace-nowrap'>{formatCurrency(ticket.price)}</div>,
  }));

  const renderActions = (row: TableTicket) => (
    <MenuDropdown
      data={row}
      options={dropDownOptions.map((option) => ({
        ...option,
        onClick: () => {
          const ticket = tickets.find((t) => t.id === row.id);
          if (ticket) option.onClick(ticket);
        },
      }))}
    />
  );

  return (
    <div>
      <FilterTable
        rows={rows}
        headCells={config}
        actions={renderActions}
        pagination={{ enabled: true, pageSize: 10 }}
        searchEnabled={true}
        customButtons={customButton ? [customButton] : []}
      />
      <CustomModal
        onRequestClose={handleClose}
        isOpen={showModal}
        hideCancelButton={true}
      >
        {modalBody}
      </CustomModal>
    </div>
  );
};

export default TicketsTable;
