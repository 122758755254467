import { createApi } from '@reduxjs/toolkit/query/react';
import EventDTO, { EventBalanceDTO, SellerEventQR } from '../../models/event';
import { Response } from '../../models/shared';
import { AgentDTO } from '../../models/agent';
import baseQueryWithReauth from './base';
import { QuestionDTO } from '../../models/poll';
import { DisplaySeatDTO } from '../../models/seat';
import UserDTO, { UserEvent } from '../../models/user';
import { CreateEventTransactionDTO, EventTransactionDTO } from '../../models/event-transaction';
import { ordersApi } from './orders';
import { CreateWireTransferDTO, WireTransferDTO } from '../../models/wire-transfer';
import { EventSeatDTO } from '../../models/event-seat';
import { referralsApi } from './referrals';

const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['events', 'events_users', 'businesses_users', 'events_transactions', 'event_seats'],
  endpoints: (build) => ({
    getEventById: build.query<EventDTO | null, { eventId: number }>({
      providesTags: (result, error, { eventId }) => [{ type: 'events', eventId }],
      query: ({ eventId }) => ({
        url: `/events/${eventId}`,
        method: 'GET',
      }),
      transformResponse: (response: Response<EventDTO>): (EventDTO | null) => response.data || null,
    }),
    getAllEvents: build.query<EventDTO[] | null, { query?: Partial<EventDTO> }>({
      providesTags: () => [{ type: 'events' }],
      query: ({ query }) => ({
        url: '/events/all/available',
        params: query,
        method: 'GET',
      }),
      transformResponse: (response: Response<EventDTO[]>): (EventDTO[] | null) => response.data || null,
    }),
    getAllBusinessEvents: build.query<Response<EventDTO[]>, number>({
      providesTags: () => [{ type: 'events' }],
      query: (business_id) => ({
        url: `/businesses/${business_id}/events`,
        method: 'GET',
      }),
    }),
    getBusinessEvents: build.query<Response<EventDTO[]>, { businessId: number, onlyActive?: boolean }>({
      providesTags: () => [{ type: 'events' }],
      query: ({ businessId, onlyActive }) => ({
        url: `/businesses/${businessId}/events`,
        method: 'GET',
        params: {
          active: onlyActive,
        },
      }),
    }),
    getCurrentAgent: build.query<AgentDTO | null, { businessId: number, eventId: number }>({
      providesTags: () => [{ type: 'events' }],
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/agents`,
        method: 'GET',
        params: {
          now: 'true',
        },
      }),
      transformResponse: (response: Response<AgentDTO>): (AgentDTO | null) => response.data || null,
    }),
    createEvent: build.mutation<Response<EventDTO>, { event: Partial<EventDTO>, businessId: number }>({
      query: ({
        event, businessId,
      }) => ({
        url: `/businesses/${businessId}/events/`,
        method: 'POST',
        body: event,
      }),
      invalidatesTags: ['events'],
    }),
    updateEvent: build.mutation<Response<EventDTO>, { event: Partial<EventDTO>, businessId: number }>({
      query: ({
        event, businessId,
      }) => ({
        url: `/businesses/${businessId}/events/`,
        method: 'PUT',
        body: event,
      }),
      invalidatesTags: ['events'],
    }),
    getEventQuestions: build.query<QuestionDTO[] | null, { eventId: number }>({
      providesTags: () => [{ type: 'events' }],
      query: ({ eventId }) => ({
        url: `/events/${eventId}/polls/questions`,
        method: 'GET',
      }),
      transformResponse: (response: Response<QuestionDTO[]>): (QuestionDTO[] | null) => response.data || null,
    }),
    getSeatsInfo: build.query<DisplaySeatDTO[] | null, { eventId: number, businessId: number }>({
      providesTags: () => [{ type: 'event_seats' }],
      query: () => ({
        url: '/businesses/3/events/12/seats?display=true',
        method: 'GET',
      }),
      transformResponse: (response: Response<DisplaySeatDTO[]>): (DisplaySeatDTO[] | null) => response.data || null,
    }),
    getEventUsers: build.query<UserDTO[] | null, { eventId: number, businessId: number }>({
      providesTags: () => [{ type: 'events_users' }],
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/users`,
        method: 'GET',
      }),
      transformResponse: (response: Response<UserDTO[]>): (UserDTO[] | null) => response.data || null,
    }),
    addUserToEvent: build.mutation<Response<UserEvent>, { businessId: number, eventId: number, userData: { userId: number, comissionRate: number } }>({
      query: ({
        businessId, eventId, userData,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/users`,
        method: 'POST',
        body: {
          user_id: userData.userId,
          seller_comission_rate: userData.comissionRate,
          permissions: 'SELLER',
          active: true,
        },
      }),
      invalidatesTags: ['events_users'],
    }),
    updateUserEvent: build.mutation<Response<UserEvent>, { businessId: number, eventId: number, data: Partial<UserEvent> & { id: number } }>({
      query: ({
        businessId, eventId, data,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/users/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['events_users'],
    }),
    deleteUserEvent: build.mutation<Response<UserEvent>, { businessId: number, eventId: number, userEventId: number }>({
      query: ({ businessId, eventId, userEventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/users/${userEventId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(referralsApi.util.invalidateTags(['referrals']));
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['events_users'],
    }),
    addEventTransaction: build.mutation<Response<CreateEventTransactionDTO>, { businessId: number, eventId: number, transactionData: CreateEventTransactionDTO }>({
      query: ({
        businessId, eventId, transactionData,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/transactions`,
        method: 'POST',
        body: transactionData,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(ordersApi.util.invalidateTags(['seller_summary']));
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['events_transactions'],
    }),
    updateEventTransaction: build.mutation<Response<EventTransactionDTO>, { businessId: number, eventId: number, transactionId: number, transactionData: Partial<EventTransactionDTO> }>({
      query: ({
        businessId, eventId, transactionId, transactionData,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/transactions/${transactionId}`,
        method: 'PUT',
        body: transactionData,
      }),
      invalidatesTags: ['events_transactions'],
    }),
    deleteEventTransaction: build.mutation<Response<EventTransactionDTO>, { businessId: number, eventId: number, transactionId: number }>({
      query: ({ businessId, eventId, transactionId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/transactions/${transactionId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(ordersApi.util.invalidateTags(['seller_summary']));
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['events_transactions'],
    }),
    getEventTransactions: build.query<Response<EventTransactionDTO[]>, { businessId: number, eventId: number }>({
      providesTags: () => [{ type: 'events_transactions' }],
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/transactions`,
        method: 'GET',
      }),
    }),
    getEventCapacity: build.query<{ used_tickets: number } | null, { eventId: number, businessId: number }>({
      providesTags: () => [{ type: 'events' }],
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/capacity`,
        method: 'GET',
      }),
      transformResponse: (response: Response<{ used_tickets: number }>): ({ used_tickets: number } | null) => response.data || null,
    }),
    countSellerQrCode: build.mutation<Response<null>, { businessId: number, eventId: number, userEventId: number }>({
      query: ({ businessId, eventId, userEventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/users/${userEventId}/qr/count`,
        method: 'POST',
      }),
    }),
    getSellerEventQr: build.query<SellerEventQR | null, { businessId: number, eventId: number, userEventId: number }>({
      providesTags: () => [{ type: 'events' }],
      query: ({ businessId, eventId, userEventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/users/${userEventId}/qr`,
        method: 'GET',
      }),
      transformResponse: (response: Response<SellerEventQR>): (SellerEventQR | null) => response.data || null,
    }),
    getEventBalance: build.query<EventBalanceDTO | null, { businessId: number, eventId: number }>({
      providesTags: () => [{ type: 'events' }],
      query: ({ businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/balance`,
        method: 'GET',
      }),
      transformResponse: (response: Response<EventBalanceDTO>): (EventBalanceDTO | null) => response.data || null,
    }),
    createWireTransfer: build.mutation<Response<WireTransferDTO>, { wire: CreateWireTransferDTO, businessId: number, eventId: number }>({
      query: ({ wire, businessId, eventId }) => ({
        url: `/businesses/${businessId}/events/${eventId}/wires`,
        method: 'POST',
        body: wire,
      }),
      invalidatesTags: ['events'],
    }),
    importSellersToEvent: build.mutation<Response<null>, { businessId: number, eventId: number, eventIdToImport: number }>({
      query: ({ businessId, eventId, eventIdToImport }) => ({
        url: `/businesses/${businessId}/events/${eventId}/import-users`,
        method: 'POST',
        body: {
          import_from: eventIdToImport,
        },
      }),
      invalidatesTags: ['events_users'],
    }),
    reserveSeat: build.mutation<Response<EventSeatDTO>, { reservationCode: string, businessId: number, eventId: number, eventSeatId: number }>({
      query: ({
        reservationCode, businessId, eventId, eventSeatId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/seats/${eventSeatId}/reserve`,
        method: 'POST',
        body: { code: reservationCode },
      }),
      invalidatesTags: ['event_seats'],
    }),
    freeSeat: build.mutation<Response<EventSeatDTO>, { reservationCode: string, businessId: number, eventId: number, eventSeatId: number }>({
      query: ({
        reservationCode, businessId, eventId, eventSeatId,
      }) => ({
        url: `/businesses/${businessId}/events/${eventId}/seats/${eventSeatId}/free`,
        method: 'POST',
        body: { code: reservationCode },
      }),
      invalidatesTags: ['event_seats'],
    }),
  }),
});

export const {
  useGetEventByIdQuery,
  useGetAllBusinessEventsQuery,
  useGetBusinessEventsQuery,
  useGetCurrentAgentQuery,
  useGetAllEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useGetEventQuestionsQuery,
  useGetSeatsInfoQuery,
  useGetEventUsersQuery,
  useAddUserToEventMutation,
  useDeleteUserEventMutation,
  useAddEventTransactionMutation,
  useDeleteEventTransactionMutation,
  useUpdateEventTransactionMutation,
  useGetEventTransactionsQuery,
  useGetEventCapacityQuery,
  useCountSellerQrCodeMutation,
  useGetSellerEventQrQuery,
  useLazyGetSellerEventQrQuery,
  useUpdateUserEventMutation,
  useGetEventBalanceQuery,
  useCreateWireTransferMutation,
  useImportSellersToEventMutation,
  useReserveSeatMutation,
  useFreeSeatMutation,
} = eventsApi;
export { eventsApi };
