import { toast } from 'react-hot-toast';
import { useState } from 'react';
import MenuDropdown from './atoms/MenuDropdown';
import { useDeleteEventTransactionMutation } from '../store';
import { EventTransactionDTO } from '../models/event-transaction';
import FilterTable, { BaseData } from './Table/Table';
import Button from './atoms/Button';
import { parseFriendlyDateAndHour } from '../utils/dates';
import { formatCurrency } from '../utils/shared';
import CustomModal from './atoms/CustomModal';
import AddUserExpense from './AddUserExpense';

interface Props {
  transactions: EventTransactionDTO[];
  businessId: number;
  eventId: number;
  role: string;
  buttonAction: () => void;
}

interface TableRows extends BaseData {
  id: number;
  date: string;
  description: string;
  amount: JSX.Element;
}

const TransactionsTable: React.FC<Props> = ({
  transactions, businessId, eventId, role, buttonAction,
}) => {
  const [deleteTransaction, resultsDelete] = useDeleteEventTransactionMutation();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState<React.ReactNode>(null);

  const handleDeleteTransaction = async (transaction: EventTransactionDTO) => {
    try {
      await toast.promise(
        deleteTransaction({
          businessId,
          eventId,
          transactionId: transaction.id,
        }).unwrap(),
        {
          loading: 'Eliminando...',
          success: <b>Transacción eliminada con éxito</b>,
          error: <b>Error al eliminar transacción.</b>,
        },
      );
      setShowModal(false);
    } catch (error) {
      console.error('Error deleting transaction:', error);
    }
  };

  const handleDeleteConfirm = (transaction: EventTransactionDTO) => {
    setShowModal(true);
    setModalBody(
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <h1 className='text-lg font-medium'>Eliminar gasto</h1>
          <p>¿Deseas eliminar este gasto?</p>
          <p className='mt-2'>Una vez eliminado desaparecerá de la lista y no se podrá revertir.</p>
        </div>
        <div className='flex gap-3'>
          <Button
            onClick={() => setShowModal(false)}
            variant='secondary'
            disabled={resultsDelete.isLoading}
          >
            Cancelar
          </Button>
          <Button
            loading={resultsDelete.isLoading}
            onClick={() => handleDeleteTransaction(transaction)}
            disabled={resultsDelete.isLoading}
          >
            Eliminar
          </Button>
        </div>
      </div>,
    );
  };

  const handleEditTransaction = (transaction: EventTransactionDTO) => {
    setShowModal(true);
    setModalBody(
      <div className='flex flex-col gap-4'>
        <div className="flex flex-col gap-2">
          <h1 className="text-xl font-medium">Editar movimiento</h1>
          <p className="text-gray-400 text-sm">
            Podrás editar gastos de este vendedor
          </p>
        </div>
        <AddUserExpense
          businessId={businessId}
          eventId={eventId}
          sellerId={transaction.user_id}
          onSuccess={() => {
            setShowModal(false);
          }}
          editTransaction
          transactionId={transaction.id}
        />
      </div>,
    );
  };

  const dropDownOptions = [
    ...(role === 'ADMIN' || role === 'ROOT' ? [{
      label: 'Editar',
      onClick: handleEditTransaction,
    }, {
      label: 'Eliminar',
      onClick: (data: EventTransactionDTO) => handleDeleteConfirm(data),
    },
    ] : []),
  ];

  const actions = (row: BaseData) => (
    <MenuDropdown<EventTransactionDTO>
      options={dropDownOptions}
      data={row as unknown as EventTransactionDTO}
      loading={resultsDelete.isLoading}
    />
  );

  const rows: TableRows[] = transactions.map((transaction) => ({
    id: transaction.id,
    date: parseFriendlyDateAndHour(transaction.created_at, false),
    description: transaction.description,
    amount: <div className='whitespace-nowrap'>{formatCurrency(transaction.amount)}</div>,
  }));

  const config = [
    {
      id: 'date',
      label: 'Fecha',
    },
    {
      id: 'amount',
      label: 'Monto',
    },
    {
      id: 'description',
      label: 'Observación',
    },
  ];

  const customButtons = [
    ...(role === 'ADMIN' || role === 'ROOT' ? [
      <Button onClick={buttonAction} variant='secondary'>
        <div className='flex items-center gap-2 whitespace-nowrap'>
          <span>+</span>
          <span className='hidden sm:inline'>Agregar gasto</span>
        </div>
      </Button>,
    ] : []),
  ];

  return (
    <div>
      {transactions.length > 0 ? (
        <FilterTable
          rows={rows}
          headCells={config}
          customButtons={customButtons}
          actions={actions}
        />
      ) : (
        <div className="flex flex-col gap-2 items-center justify-center h-full border border-gray-200 rounded-lg bg-white shadow-sm p-4">
          <h1 className="text-lg font-medium text-center">Este vendedor no tiene ningún gasto hasta el momento</h1>
          <div>
            <Button onClick={buttonAction}>Agregar gastos</Button>
          </div>
        </div>
      )}
      <CustomModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        hideCancelButton
      >
        {modalBody}
      </CustomModal>
    </div>

  );
};

export default TransactionsTable;
