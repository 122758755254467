/* eslint-disable no-nested-ternary */
import { useParams } from 'react-router-dom';
import { HiOutlineRocketLaunch } from 'react-icons/hi2';
import { Fragment, useState } from 'react';
import OrdersTableSkeleton from '../components/OrdersTableSkeleton';
import { useGetAllTicketsQuery } from '../store';
import IconInfoCard from '../components/atoms/IconInfoCard';
import TicketsTable from '../components/TicketsTable';
import CreateTicket from '../components/CreateTicket';
import CustomModal from '../components/atoms/CustomModal';
import AdminSectionTitle from '../components/AdminSectionTitle';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';

const AdminTickets: React.FC = () => {
  const { event_id: eventId, business_id: businessId } = useParams();
  const [showModal, setShowModal] = useState(false);

  if (!eventId || !+eventId || !businessId || !+businessId) {
    throw new Error('no event_id or business_id');
  }

  const { data: tickets, isFetching } = useGetAllTicketsQuery({
    businessId: +businessId,
    eventId: +eventId,
  });
  const { event } = useGetSelectedEvent();

  const handleSucessAddTicket = () => {
    setShowModal(false);
  };

  const handleAddTicket = () => {
    setShowModal(true);
  };

  return (
    <div className="flex flex-col gap-8 w-full my-6">
      <AdminSectionTitle
        title="Eventos"
        subtitle={`${event?.name} - Entradas`}
      />
      <div>
        {isFetching && <OrdersTableSkeleton />}{' '}
        {tickets && tickets.length > 0 && (
          <TicketsTable tickets={tickets} eventId={+eventId} businessId={+businessId} button={{
            label: 'Crear nueva entrada',
            onClick: handleAddTicket,
            variant: 'secondary',
            image: '/ticket-01.svg',
          }} />
        )}
        {tickets && tickets.length === 0 && (
          <IconInfoCard
            title="Aún no hay tickets creados"
            info="Aquí aparecerán los diferentes tipos de entradas que crees"
            icon={HiOutlineRocketLaunch}
            iconColor="text-indigo-600"
          />
        )}
      </div>
      <CustomModal
        onRequestClose={() => setShowModal(false)}
        isOpen={showModal}
        hideCancelButton={true}
>
        <Fragment>
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-2xl font-medium">Crear entrada</h1>
          </div>
          <CreateTicket onSuccess={handleSucessAddTicket} onCancel={() => setShowModal(false)} />
        </Fragment>
      </CustomModal>
    </div>
  );
};

export default AdminTickets;
