import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../store';
import config from '../config/config';
import Button from '../components/atoms/Button';
import TextTitleCard from '../components/atoms/TitleTextCard';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';

const PollsPage: React.FC = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { token } = useSelector(
    (state: RootState) => state.auth,
  );
  const { event_id: eventId, business_id: businessId } = useParams();
  const event = useSelector((state: RootState) => state.events.selectedEvent);
  const navigate = useNavigateWithQuery();

  const handleDownloadExport = () => {
    setIsDownloading(true);
    axios
      .get(
        `${config.baseURL}/v1/businesses/${businessId}/events/${eventId}/polls/responses`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `respuestas_${event?.name ?? ''}_${new Date().toISOString()}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => console.error(e))
      .finally(() => setIsDownloading(false));
  };

  const handleNext = () => {
    navigate('../checkout', { replace: true });
  };

  const handleBack = () => {
    navigate('../entry-data');
  };

  return (
    <div className='flex flex-col gap-4 align-items-center justify-center mt-5'>
      <TextTitleCard title='Descargar reporte'>
        <span className='mb-5'>Actualmente estamos trabajando en mejorar esta página, por ahora puedes descargar un reporte de excel.</span>
        <Button onClick={handleDownloadExport} loading={isDownloading}>
          Descargar respuestas
        </Button>
      </TextTitleCard>

      <div className="flex flex-col-reverse md:flex-row gap-8 justify-center w-full">
        <Button variant="secondary" onClick={handleBack}>
          Volver
        </Button>
        <Button variant="primary" onClick={handleNext}>
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default PollsPage;
