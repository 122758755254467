import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Input from '../atoms/Input';
import Button from '../atoms/Button';
import './Table.styles.css';

export interface BaseData {
  id: number;
  [key: string]: string | number | JSX.Element;
}

interface HeadCell<T extends BaseData> {
  id: keyof T;
  label: string;
}

interface EnhancedTableProps<T extends BaseData> {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => void;
  allowSelect?: boolean;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: keyof T;
  rowCount: number;
  headCells: readonly HeadCell<T>[];
  actions: boolean;
}

const descendingComparator = <T extends BaseData>(
  a: T,
  b: T,
  orderBy: keyof T,
): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

type Order = 'asc' | 'desc';

const getComparator = <T extends BaseData>(
  order: Order,
  orderBy: keyof T,
): ((a: T, b: T) => number) => (order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy));

function EnhancedTableHead<T extends BaseData>({
  allowSelect = false,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  actions,
}: EnhancedTableProps<T>) {
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {allowSelect && (
          <TableCell padding="checkbox" sx={{
            width: '40px',
            padding: '16px 16px 16px 16px',
            whiteSpace: 'nowrap',
          }}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              icon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="white" />
                <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="#D1D1D6" />
              </svg>
              }
              checkedIcon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="20" height="20" rx="6" fill="#755DBA" />
                <path d="M14.6666 6.5L8.24998 12.9167L5.33331 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>}
            />
          </TableCell>
        )}

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id as React.Key}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              padding: '12px 12px',
              whiteSpace: 'normal',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ whiteSpace: 'normal' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? <div></div> : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actions && <TableCell sx={{ width: '80px', padding: '12px 12px' }}>Acciones</TableCell>}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar: React.FC<{
  showFilter?: boolean,
  showSearch?: boolean,
  onSearch?: (query: string) => void,
  searchQuery?: string,
  customButtons?: JSX.Element[];
  loading?: boolean;

}> = ({
  showFilter, showSearch, onSearch, searchQuery, customButtons,
}) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => { onSearch?.(event.target.value); };

  return showFilter || showSearch || customButtons ? (
      <Toolbar>
        <div className='flex flex-row w-full py-4 gap-4 items-center'>
          {showSearch && (
            <div className="w-full">
              <Input
                id={'search'}
                label={'Buscar'}
                isSearch={true}
                onChange={handleSearch}
                value={searchQuery}
              />
            </div>
          )}

          {showFilter && (
            <div className='flex items-center w-fit whitespace-nowrap'>
              <Button variant="secondary">
                <div className='flex flex-row gap-2 items-center'>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4L9 12V18L15 21V12L20 4H4Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span className='whitespace-nowrap hidden md:block'>Filtrar</span>
                </div>
              </Button>
            </div>

          )}

          {customButtons && customButtons.map((button, index) => (
            <div key={`custom-button-${index}`} className='flex w-fit items-center ml-auto'>
              {button}
            </div>
          ))}

        </div>
      </Toolbar>) : null;
};

interface FilterTableProps<T extends BaseData> {
  rows: T[];
  headCells: readonly HeadCell<T>[];
  actions?: (row: T) => JSX.Element;
  pagination?: {
    pageSize?: number;
    enabled: boolean;
  }
  filtering?: {
    enabled: boolean;
  }
  searchEnabled?: boolean;
  allowSelect?: boolean;
  onSelectionChange?: (selectedIds: readonly number[]) => void;
  customButtons?: JSX.Element[];
}

function FilterTable<T extends BaseData>({
  rows, headCells, actions, pagination, filtering, searchEnabled = true, allowSelect = false, onSelectionChange, customButtons,
}: FilterTableProps<T>) {
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof T>('');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [rowsPerPage] = React.useState(pagination?.pageSize || 5);

  const visibleRows = React.useMemo(
    () => {
      if (rows.length === 0) {
        return [];
      }

      // Filter rows based on search query
      const filteredRows = searchQuery
        ? rows.filter((row) => Object.values(row).some((value) => String(value).toLowerCase().includes(searchQuery.toLowerCase())))
        : rows;

      const sortedRows = [...filteredRows].sort(getComparator(order, orderBy));
      return pagination?.enabled
        ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : sortedRows;
    },
    [order, orderBy, page, rowsPerPage, rows, pagination?.enabled, searchQuery],
  );

  // Calculate totalPages based on filtered results
  const totalPages = React.useMemo(() => {
    const filteredRowCount = searchQuery
      ? rows.filter((row) => Object.values(row).some((value) => String(value).toLowerCase().includes(searchQuery.toLowerCase()))).length
      : rows.length;
    return pagination?.enabled ? Math.ceil(filteredRowCount / rowsPerPage) : 1;
  }, [rows, searchQuery, pagination?.enabled, rowsPerPage]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const isDesc = orderBy === property && order === 'desc';

    if (isDesc) {
      setOrder('asc');
      setOrderBy('');
    } else {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      onSelectionChange?.(newSelected);
      return;
    }
    setSelected([]);
    onSelectionChange?.([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    onSelectionChange?.(newSelected);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < totalPages) {
      setPage(newPage);
    }
  };

  const getPageNumbers = (totalPageNumber: number, currentPage: number) => {
    const delta = 1;
    const pages: (number | string)[] = [];

    for (let i = 0; i < totalPageNumber; i += 1) {
      if (
        i === 0
        || i === totalPageNumber - 1
        || (i >= currentPage - delta && i <= currentPage + delta)
      ) {
        pages.push(i);
      } else if (
        i === currentPage - delta - 1
        || i === currentPage + delta + 1
      ) {
        pages.push('...');
      }
    }

    return pages.filter((currPage, index, array) => currPage !== '...' || (currPage === '...' && array[index - 1] !== '...'));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar showFilter={filtering?.enabled} showSearch={searchEnabled} onSearch={setSearchQuery} searchQuery={searchQuery} customButtons={customButtons} />
        {visibleRows.length > 0 ? (
          <TableContainer
            sx={{
              overflowX: 'auto',
              '@media (max-width: 600px)': {
                maxWidth: '100vw',
              },
              '&::-webkit-scrollbar': {
                height: '2px',
                width: '2px',
                display: 'block',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }}
          >
            <Table
              sx={{
                minWidth: '100%',
                '& .MuiTableCell-root': {
                  padding: '8px 20px',
                },
              }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                allowSelect={allowSelect}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
                actions={!!actions}
              />
              <TableBody>

                {visibleRows.map((row, index) => {
                  const isItemSelected = selected.includes(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return allowSelect ? (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox" sx={{
                        width: '40px',
                        padding: '0 0 0 16px',
                        whiteSpace: 'nowrap',
                      }}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          icon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="white" />
                            <rect x="0.5" y="0.5" width="19" height="19" rx="5.5" stroke="#D1D1D6" />
                          </svg>}
                          checkedIcon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="20" height="20" rx="6" fill="#755DBA" />
                            <path d="M14.6666 6.5L8.24998 12.9167L5.33331 10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>}
                        />
                      </TableCell>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id as React.Key}
                          component={headCell.id === headCells[0].id ? 'th' : 'td'}
                          id={headCell.id === headCells[0].id ? labelId : undefined}
                          sx={{
                            pading: '12px 12px',
                            minWidth: 'auto',
                            width: 'auto',
                            wordBreak: 'break-word',
                          }}
                        >
                          {row[headCell.id]}
                        </TableCell>
                      ))}
                      {actions && (
                        <TableCell sx={{ padding: '8px 12px' }}>
                          {actions(row)}
                        </TableCell>
                      )}
                    </TableRow>
                  ) : (
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id as React.Key}
                          sx={{
                            padding: '12px 12px',
                            minWidth: 'auto',
                            width: 'auto',
                            wordBreak: 'break-word',
                          }}
                        >
                          {row[headCell.id]}
                        </TableCell>
                      ))}
                      {actions && (
                        <TableCell sx={{ padding: '12px 12px' }}>
                          {actions(row)}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
                {rows.length === 0 && (
                  <TableRow style={{ height: 53 }}>
                    <TableCell colSpan={headCells.length + 1} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="flex flex-col items-center justify-center h-full mt-4 mb-10">
            <h1 className="text-2xl font-medium text-gray-600 text-center">No se encontraron resultados</h1>
            <p className="text-gray-500 text-center">Intenta ajustar tu búsqueda o filtros para encontrar lo que estás buscando</p>
          </div>
        )}
        {pagination?.enabled && rows.length > 0 && (
          <div className={`${visibleRows.length === 0 ? 'border-t border-gray-200' : ''}`}>
            <Box className="grid grid-cols-3 items-center gap-4 sm:gap-2 px-6 py-4 max-[830px]:hidden">
              <div className='w-fit'>
                <Button
                  variant="secondary"
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 0}
                >
                  Anterior
                </Button>
              </div>

              <div className="flex flex-row items-center justify-center w-fit mx-auto">
                {getPageNumbers(totalPages, page).map((pageNum, index) => (
                  pageNum === '...' ? (
                    <span key={`ellipsis-${index}`} className="px-2 max-w-fit">...</span>
                  ) : (
                    <Button
                      key={pageNum}
                      onClick={() => handlePageChange(pageNum as number)}
                      variant='page'
                      isSelect={page === pageNum}
                    >
                      {(pageNum as number) + 1}
                    </Button>
                  )
                ))}
              </div>

              <div className='w-fit justify-self-end'>
                <Button
                  variant="secondary"
                  onClick={() => handlePageChange(page + 1)}
                  disabled={page >= totalPages - 1}
                >
                  Siguiente
                </Button>
              </div>
            </Box>
            <div className="grid grid-cols-3 min-[830px]:hidden items-center justify-center h-full p-4">
              <Button variant="page" className="justify-self-start" onClick={() => handlePageChange(page - 1)}>{'<'}</Button>
              <span className="text-center text-sm">{visibleRows.length === 0 ? 'Página 1 de 1' : `Página ${page + 1} de ${totalPages}`}</span>
              <Button variant="page" className="justify-self-end" onClick={() => handlePageChange(page + 1)}>{'>'}</Button>
            </div>
          </div>
        )}
      </Paper>
    </Box>
  );
}

export default FilterTable;
