import { TicketSummaryDTO } from '../models/order';
import { formatCurrency } from '../utils/shared';
import FilterTable, { BaseData } from './Table/Table';

interface Props {
  summaries: TicketSummaryDTO[];
}

interface TableRows extends BaseData {
  id: number;
  ticket_name: string | JSX.Element;
  ticket_price: string | JSX.Element;
  amount: number;
  total: string | JSX.Element;
}

const TicketsSummaryTable: React.FC<Props> = ({ summaries }) => {
  const rows: TableRows[] = summaries.map((summary) => ({
    id: summary.ticket_id,
    ticket_name: summary.ticket_name,
    ticket_price: <div className='whitespace-nowrap'>{formatCurrency(summary.ticket_price)}</div>,
    amount: summary.count,
    total: <div className='whitespace-nowrap'>{formatCurrency(summary.total)}</div>,
  }));

  const config = [
    {
      id: 'ticket_name',
      label: 'Ticket',
    },
    {
      id: 'ticket_price',
      label: 'Precio',
    },

    {
      id: 'amount',
      label: 'Cantidad',
    },
    {
      id: 'total',
      label: 'Total',
    },
  ];

  return (
    <div>
      {summaries.length > 0 ? (
        <FilterTable
          rows={rows}
          headCells={config}
          pagination={{ enabled: false }}
          searchEnabled={false}
        />
      ) : (
        <div className='flex flex-col gap-2 items-center justify-center h-full border border-gray-200 rounded-lg bg-white shadow-sm p-4'>
          <h1 className='text-lg font-medium text-center'>No hay resumen de entradas</h1>
        </div>
      )}
    </div>

  );
};

export default TicketsSummaryTable;
