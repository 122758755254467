import React from 'react';

const BizEventSummarySkeleton = () => (
    <div className="bg-white rounded-lg p-6 flex flex-col gap-4">
      {/* Header skeleton */}
      <div className="flex flex-col gap-2">
        <div className="h-6 w-24 bg-gray-200 rounded animate-pulse" />
        <div className="h-4 w-40 bg-gray-200 rounded animate-pulse" />
      </div>

      {/* Items skeleton */}
      <div className="flex flex-col gap-4">
        {Array(3).fill(0).map((_, index) => (
          <div key={index} className="flex items-center justify-between p-4 border rounded-lg">
            <div className="flex flex-col gap-2">
              <div className="h-4 w-32 bg-gray-200 rounded animate-pulse" />
              <div className="h-3 w-24 bg-gray-200 rounded animate-pulse" />
            </div>
            <div className="h-8 w-24 bg-gray-200 rounded animate-pulse" />
          </div>
        ))}
      </div>
    </div>
);

export default BizEventSummarySkeleton;
