/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';

import NumeratedTicketsEntry from '../components/NumeratedTicketsEntry';
import NumeratedOrderDetails from '../components/NumeratedOrderDetails';
import RenderStageMap from '../components/RenderStageMap';

import { RootState, setReservation, useGetAllTicketsQuery } from '../store';

import TicketDTO from '../models/ticket';
import { DisplaySeatDTO } from '../models/seat';

import { useFreeSeatMutation, useGetSeatsInfoQuery } from '../store/apis/events';
import CountdownTimer from '../components/CountdownTimer';
import Button from '../components/atoms/Button';
import { setTicketsSelected } from '../store/slices/tickets';
import { useCreateReservationMutation, useGetReservationAndSeatsQuery } from '../store/apis/tickets';
import LoadingLottie from '../components/atoms/LoadingLottie';
import { TicketSelection } from '../components/SelectTicketsTable';
import { selectReservation } from '../store/slices/orders';
import { toastError } from '../utils/toasts';

const SelectSeatsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { event_id: eventId } = useParams();
  const location = useLocation();
  const isBackoffice = location.pathname.includes('admin');
  const event = useSelector((state: RootState) => state.events.selectedEvent);
  const reservation = useSelector((state: RootState) => state.orders.reservation);
  const { data: eventTickets, isLoading: isLoadingTickets } = useGetAllTicketsQuery(
    { businessId: event?.business_id || 0, eventId: +(eventId || 0) },
    { skip: !event || !eventId },
  );
  const [tickets, setTickets] = useState<TicketDTO[]>([]);
  const [, setTicketKey] = useState(0);
  const [selectedSeats, setSelectedSeats] = useState<DisplaySeatDTO[]>([]);
  const [selectedTickets, setSelectedTickets] = useState<TicketSelection[]>([]);
  const { data: displaySeats, isLoading, refetch: refetchSeats } = useGetSeatsInfoQuery({
    eventId: +(eventId || 0),
    businessId: event?.business_id || 0,
  });
  const { data: reservationAndSeats } = useGetReservationAndSeatsQuery({
    businessId: event?.business_id ?? 0,
    eventId: event?.id ?? 0,
    reservationCode: reservation?.code ?? '',
  }, { skip: !reservation || !event });
  const [createReservation, { data: reservationCreated, isLoading: isReserveLoading }] = useCreateReservationMutation();
  const [freeSeat, freeSeatResults] = useFreeSeatMutation();

  const handleSeatsSelected = async (seats: DisplaySeatDTO[]) => {
    const removedSeats = selectedSeats.filter(
      (prevSeat) => !seats.some((newSeat) => newSeat.id === prevSeat.id),
    );
    const seatToRemove = removedSeats[0] ?? null;

    if (seatToRemove && reservation?.code && event) {
      try {
        await freeSeat({
          businessId: event.business_id,
          eventId: event.id,
          reservationCode: reservation.code,
          eventSeatId: seatToRemove.id,
        });
        setSelectedSeats(seats);
      } catch (error) {
        console.error('Error freeing seat:', error);
        toastError('Error al liberar la butaca');
      }
    }
    if (!seatToRemove) {
      setSelectedSeats(seats);
    }
  };

  useEffect(() => {
    localStorage.removeItem('formItemsData');
  }, []);

  const [hasCheckedReservation, setHasCheckedReservation] = useState(false);
  const reservationCreatedRef = useRef(false);

  useEffect(() => {
    if (event && !hasCheckedReservation) {
      dispatch(selectReservation());
      setHasCheckedReservation(true);
    }
  }, [event, dispatch, hasCheckedReservation]);

  useEffect(() => {
    if (event && hasCheckedReservation && !reservation && !reservationCreatedRef.current) {
      reservationCreatedRef.current = true;
      createReservation({
        businessId: event.business_id,
        eventId: event.id,
      });
    } else if (event && hasCheckedReservation && reservation && reservationAndSeats) { // has reservation loaded
      if (reservationAndSeats) {
        setSelectedSeats(reservationAndSeats.reservation_seats);
      }
    }
  }, [event, reservation, hasCheckedReservation, createReservation, reservationAndSeats]);

  useEffect(() => {
    if (reservationCreated?.data?.code) {
      dispatch(setReservation(reservationCreated.data));
    }
  }, [reservationCreated, dispatch]);

  useEffect(() => {
    setSelectedTickets(selectedSeats.map((s) => {
      const ticket = tickets.find((t) => t.id === s.ticket_id);
      const reservationCode = reservation?.code;
      if (!ticket) {
        throw new Error('Ticket not found');
      }
      if (!reservationCode) {
        throw new Error('Reservation code not found');
      }
      return {
        ticket,
        quantity: 1,
        seat_name: s.seat_name,
        seat_id: s.id,
      };
    }));
  }, [selectedSeats, tickets]);

  useEffect(() => {
    if (!event) {
      navigate('../../');
    }
  }, [event, navigate]);

  useEffect(() => {
    setTickets(
      eventTickets?.filter((t) => (isBackoffice ? t : t.active)) || [],
    );
    setTicketKey((prevKey) => prevKey + 1);
  }, [eventTickets]);

  useEffect(() => {
    refetchSeats();
  }, []);

  const handleClickNext = async () => {
    if (selectedSeats.length === 0) {
      return '';
    }
    const reserveCode = reservation?.code;
    if (!reserveCode) {
      console.error('no reserve code');
      return '';
    }
    dispatch(setTicketsSelected([...selectedTickets]));
    return navigate('../entry-data', { replace: true });
  };

  const handleBack = () => {
    navigate('../tickets');
  };

  if (isLoadingTickets || isLoading || isReserveLoading) {
    return <LoadingLottie />;
  }

  return (
    <div>
      <div>
        {displaySeats && (
          <RenderStageMap
            seatsInfo={displaySeats}
            selectedSeats={selectedSeats}
            onSeatsUpdate={handleSeatsSelected}
          />
        )}
        {reservation && <CountdownTimer expirationDate={new Date(reservation.expires_at)} />}
      </div>
      <NumeratedTicketsEntry tickets={tickets} />
      {selectedSeats?.length > 0 && <div className='mt-5'>
        <NumeratedOrderDetails
          displaySeats={selectedSeats}
          onSeatsUpdate={handleSeatsSelected}
          isLoading={freeSeatResults.isLoading}
        />
      </div>}
      <Row gutter={[16, 0]} className="my-6">
        <Col span={12}>
          <Button type="button" className="w-full" onClick={handleBack}>
            Atras
          </Button>
        </Col>
        <Col span={12}>
          <Button
            type="submit"
            className="w-full"
            onClick={handleClickNext}
            loading={isReserveLoading}
            disabled={!selectedSeats.length}
            variant="primary"
          >
            Siguiente
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SelectSeatsPage;
