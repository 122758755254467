import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { RootState, useGetOneSellerSummaryQuery } from '../store';
import TicketsSummaryTable from './SellersTicketSummary';
import ApprovedOrdersTable from './ApprovedOrdersTable';
import parseOrderToOrderItem from '../utils/parseOrderToOrderItem';
import OrderItem from '../types/OrderItem';
import useGetUserRole from '../hooks/useGetUserRole';
import useGetParams from '../hooks/useGetParams';
import TransactionsTable from './TransactionsTable';
import CustomModal from './atoms/CustomModal';
import AddUserExpense from './AddUserExpense';
import { setSellerDetails } from '../store/slices/orders';
import AdminSectionTitle from './AdminSectionTitle';
import AddEditSellerToEvent from './AddEditSellerToEvent';
import { SellerSummaryDTO } from '../models/order';
import { useLazyGetSellerEventQrQuery, useDeleteUserEventMutation, useGetEventByIdQuery } from '../store/apis/events';
import Button from './atoms/Button';
import { formatCurrency } from '../utils/shared';

const SellersSummaryDetails: React.FC = () => {
  const [deleteUserEvent] = useDeleteUserEventMutation();
  const summary = useSelector((state: RootState) => state.orders.sellerDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState<OrderItem[]>([]);
  const role = useGetUserRole();
  const { businessId, eventId } = useGetParams();
  const { data: event } = useGetEventByIdQuery({ eventId: +eventId });
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState<React.ReactNode>(null);
  const [getSellerEventQr] = useLazyGetSellerEventQrQuery();
  const { data: refreshedSummary } = useGetOneSellerSummaryQuery(
    {
      businessId,

      eventId,
      sellerId: summary?.seller_id ?? 0,
    },
    {
      skip: !summary?.seller_id,
    },
  );

  useEffect(() => {
    if (refreshedSummary) {
      dispatch(setSellerDetails(refreshedSummary));
    }
  }, [refreshedSummary]);

  useEffect(() => {
    if (summary) {
      setOrders(parseOrderToOrderItem(summary.seller_orders));
    } else {
      console.error('no summary');
      navigate('..');
    }
  }, [summary, navigate]);

  if (!summary) {
    return <div>no hay reporte</div>;
  }

  const handleDeleteUserEvent = async (user: SellerSummaryDTO) => {
    const res = await toast.promise(
      deleteUserEvent({
        businessId,
        eventId: +eventId,
        userEventId: user.user.current_event.id,

      }).unwrap(),
      {
        loading: 'Eliminando...',
        success: <b>Vendedor eliminado con éxito</b>,
        error: <b>Error al eliminar vendedor.</b>,
      },
    );
    if (res) {
      navigate('..');
    }
  };

  const handleDeleteConfirm = () => {
    setShowModal(true);
    setModalBody(
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <h1 className='text-lg font-medium'>Eliminar vendedor</h1>
          <p>¿Deseas eliminar a este vendedor?</p>
          <p className='mt-2'>Una vez eliminado desaparecerá de la lista y no se podrá revertir.</p>
        </div>
        <div className='flex gap-3'>
          <Button onClick={() => setShowModal(false)} variant='secondary'>Cancelar</Button>
          <Button disabled={summary.total_items_count > 0} onClick={() => handleDeleteUserEvent(summary)}>Eliminar</Button>
        </div>
      </div>,
    );
  };

  const handleEditUser = (sellerSummary: SellerSummaryDTO) => {
    setShowModal(true);
    setModalBody(
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-2'>
          <h1 className='text-xl font-medium'>Editar comisión</h1>
          <p className='text-gray-400 text-sm text-center'>
            Podrás editar la comisión de este vendedor
          </p>
        </div>
        <AddEditSellerToEvent
          businessId={businessId}
          eventId={eventId}
          onSuccess={() => setShowModal(false)}
          sellerToEdit={{
            userId: sellerSummary.seller_id,
            comissionRate: sellerSummary.user.current_event.seller_comission_rate,
          }}

          onClose={() => setShowModal(false)}
        />
      </div>,
    );
  };

  const handleDownloadQR = async (sellerSummary: SellerSummaryDTO) => {
    const res = await toast.promise(
      getSellerEventQr({
        businessId,
        eventId: +eventId,
        userEventId: sellerSummary.user.current_event.id,
      }).unwrap(),

      {
        loading: 'Descargando QR...',
        success: <b>QR descargado con éxito</b>,
        error: <b>Error al descargar QR.</b>,
      },
    );

    if (res) {
      const a = document.createElement('a');
      a.href = `data:image/png;base64,${res.qr_image_b64}`;
      a.download = `qr_rrpp_${sellerSummary.seller_name}.png`;
      a.click();
    }
  };

  const mainButtons = [
    ...(summary?.user?.current_event?.seller_comission_rate && (role === 'ADMIN' || role === 'ROOT') ? [{
      label: 'Editar vendedor',
      onClick: () => handleEditUser(summary),
      variant: 'secondary' as const,
      image: '/edit-3.svg',
    }] : []),
    ...(summary?.user?.current_event?.id && (event?.has_seller_qr === true) && (role === 'ADMIN' || role === 'ROOT') ? [{
      label: 'QR vendedor',
      onClick: () => handleDownloadQR(summary),
      variant: 'secondary' as const,
      image: '/qr-code-02.svg',
    }] : []),
    ...(summary?.user?.current_event?.seller_comission_rate && (role === 'ADMIN' || role === 'ROOT') ? [{
      label: 'Eliminar vendedor',
      onClick: () => handleDeleteConfirm(),
      variant: 'secondary' as const,
      image: '/trash.svg',
    }] : []),
  ];

  const handleAddExpense = () => {
    setShowModal(true);
    setModalBody(
      <div className='flex flex-col gap-4'>
        <div className="flex flex-col gap-2">
          <h1 className="text-xl font-medium">Agregar movimiento</h1>
          <p className="text-gray-400 text-sm">
            Podrás agregar gastos a este vendedor
          </p>
        </div>
        <AddUserExpense
          businessId={businessId}
          eventId={eventId}
          sellerId={summary.seller_id}
          onSuccess={() => setShowModal(false)}
        />
      </div>,
    );
  };

  return (
    <div className="flex flex-col gap-6 my-6">

      <AdminSectionTitle
        title="Eventos"
        subtitle={`Reporte ${summary.seller_name}`}
        mainButtons={mainButtons}
      />

      <div className="flex flex-col gap-4">
        <h1 className="text-xl font-semibold">Resumen de entradas</h1>
        <TicketsSummaryTable summaries={summary.tickets_summary} />
      </div>

      <div className='flex flex-col gap-4 md:max-w-xl'>
        <h1 className='text-xl font-semibold'>Balance</h1>
        <div className='flex flex-col gap-2 border border-gray-200 rounded-lg bg-white shadow-sm'>
          <div className='flex flex-col'>
            <div className='grid grid-cols-2 gap-2 px-6 py-4 border-b border-gray-200'>
              <h2 className='text-sm'>Total menta manual</h2>
              <p className='text-sm'>{formatCurrency(summary.manual_amount)}</p>
            </div>
            <div className='grid grid-cols-2 gap-2 px-6 py-4 border-b border-gray-200'>
              <h2 className='text-sm'>Total venta por link</h2>
              <p className='text-sm'>{formatCurrency(summary.referral_amount)}</p>
            </div>
            <div className='grid grid-cols-2 gap-2 px-6 py-4 border-b border-gray-200'>
              <h2 className='text-sm'>Comisión del vendedor</h2>
              <p className='text-sm'>{formatCurrency(-summary.total_comission_amount)}</p>
            </div>
            <div className='grid grid-cols-2 gap-2 px-6 py-4 border-b border-gray-200'>
              <h2 className='text-sm'>Egresos</h2>
              <p className='text-sm'>{formatCurrency(-summary.transactions_summary.total_amount)}</p>
            </div>
            <div className='grid grid-cols-2 gap-2 px-6 py-4 border-b border-gray-200 bg-gray-50'>
              <h2 className='text-sm'>Saldo</h2>
              <p className='text-sm'>{formatCurrency(summary.balance)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-4'>
        <h1 className='text-xl font-semibold'>Entradas vendidas</h1>
        <ApprovedOrdersTable orderItems={orders} />
      </div>

      <div className='flex flex-col gap-4'>
        <h1 className='text-xl font-semibold'>Gastos</h1>
        <TransactionsTable
          transactions={summary.transactions_summary.transactions}
          businessId={businessId}
          eventId={eventId}
          role={role}
          buttonAction={handleAddExpense}
        />
      </div>

      <CustomModal
        onRequestClose={() => setShowModal(false)}
        isOpen={showModal}
        hideCancelButton
      >
        <Fragment>
          {modalBody}
        </Fragment>
      </CustomModal>
    </div>
  );
};

export default SellersSummaryDetails;
