import { Fragment, useEffect, useState } from 'react';
import { HiOutlineRocketLaunch } from 'react-icons/hi2';
import { FaUserAltSlash } from 'react-icons/fa';
import useGetParams from '../hooks/useGetParams';
import SellersTable from '../components/SellersTable';
import UserDTO, {
  UserWithBusiness,
  UserWithBusinessAndEvent,
} from '../models/user';
import {
  useGetBusinessUsersQuery,
  useGetEventUsersQuery,
} from '../store';
import OrdersTableSkeleton from '../components/OrdersTableSkeleton';
import IconInfoCard from '../components/atoms/IconInfoCard';
import Button from '../components/atoms/Button';
import CustomModal from '../components/atoms/CustomModal';
import AddEditSellerToEvent from '../components/AddEditSellerToEvent';
import ImportSellersToEvent from '../components/ImportSellersToEvent';
import AdminSectionTitle from '../components/AdminSectionTitle';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';

const getEventSellers = (users: UserDTO[], eventId: number) => users
  .map((u) => ({
    ...u,
    user_business: u.user_businesses.find(
      (ub) => ub.permissions === 'SELLER',
    ),
    user_event: u.user_events.find((ue) => ue.event_id === eventId),
  }))
  .filter(
    (u): u is UserWithBusinessAndEvent => u.user_business !== undefined && u.user_event !== undefined,
  )
  .sort((a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()));

const getSellersNotInEvent = (
  users: UserDTO[],
  businessId: number,
  eventId: number,
) => {
  const bizUsers: UserWithBusiness[] = users
    .map((u) => ({
      ...u,
      user_business: u.user_businesses.find(
        (ub) => ub.business_id === businessId,
      ),
    }))
    .filter((u): u is UserWithBusiness => u.user_business !== undefined);
  const sellerUsers = bizUsers.filter(
    (u) => u.user_business.permissions === 'SELLER',
  );
  const sellersNotInEvent = sellerUsers.filter(
    (u) => !u.user_events.find((ue) => ue.event_id === eventId),
  );
  return sellersNotInEvent;
};

const AdminSellersPage = () => {
  const { event } = useGetSelectedEvent();
  const { eventId, businessId } = useGetParams();
  const [eventSellers, setEventSellers] = useState<UserWithBusinessAndEvent[]>(
    [],
  );
  const [showModal, setShowModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [sellersNotInEvent, setSellersNotInEvent] = useState<UserDTO[]>([]);
  const { data: eventUsers, isFetching: isFetchingEventUsers } = useGetEventUsersQuery({
    businessId,
    eventId,
  });

  const {
    data: bizUsers,
    isFetching: isFetchingBizUsers,
    refetch: refetchBizUsers,
  } = useGetBusinessUsersQuery({
    businessId,
  });

  useEffect(() => {
    if (eventUsers) {
      setEventSellers(getEventSellers(eventUsers, eventId));
      refetchBizUsers();
    }
  }, [eventUsers]);

  useEffect(() => {
    if (bizUsers) {
      setSellersNotInEvent(getSellersNotInEvent(bizUsers, businessId, eventId));
    }
  }, [bizUsers]);

  const handleAddSeller = () => {
    setShowModal(true);
  };

  const handleSucessInviteUser = () => {
    setShowImportModal(false);
  };

  const handleImportSellers = () => {
    setShowImportModal(true);
  };

  return (
    <div className="my-6 flex flex-col gap-8">
      <div className="flex flex-col gap-8">
        <AdminSectionTitle title="Eventos" subtitle={`${event?.name} - Vendedores`}/>
        {(isFetchingEventUsers || isFetchingBizUsers) && (
          <OrdersTableSkeleton />
        )}{' '}
        {eventSellers && eventSellers.length > 0 && (
          <SellersTable
            businessId={businessId}
            eventId={eventId}
            button={{
              label: 'Agregar vendedor',
              onClick: handleAddSeller,
              variant: 'secondary',
              image: '/ticket-01.svg',
            }}
          />
        )}
        {eventSellers && !isFetchingEventUsers && !isFetchingBizUsers && eventSellers.length === 0 && (
          <div>
            <IconInfoCard
              title="Aún no hay vendedores en este evento"
              info="Aquí aparecerán los vendedores de este evento"
              icon={HiOutlineRocketLaunch}
              iconColor="text-indigo-600"
            />
            <Button variant="primary" onClick={handleImportSellers}>
              Importar vendedores
            </Button>
          </div>
        )}
        <CustomModal
          onRequestClose={() => setShowImportModal(false)}
          isOpen={showImportModal}
          actionBar={<div></div>}
        >
          <Fragment>
            <div className="flex flex-col justify-center items-center mb-5">
              <h1 className="text-xl font-medium mb-4">Importar vendedores</h1>
              <p className="text-gray-400 text-sm text-center">
                Podrás importar vendedores a este evento de otro evento de tu organización
              </p>
            </div>
            {sellersNotInEvent.length > 0 && (
              <ImportSellersToEvent
                businessId={businessId}
                eventId={eventId}
                onSuccess={handleSucessInviteUser}
              />
            )}
          </Fragment>
        </CustomModal>
        <CustomModal
          onRequestClose={() => setShowModal(false)}
          isOpen={showModal}
          actionBar={<div></div>}
          hideCancelButton
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <h1 className="text-xl font-medium">Agregar vendedor</h1>
              <p className="text-gray-400 text-sm">
                Podrás agregar vendedores a este evento
              </p>
            </div>
            {sellersNotInEvent.length > 0 && (
              <AddEditSellerToEvent
                businessId={businessId}
                eventId={eventId}
                users={sellersNotInEvent}
                onSuccess={handleSucessInviteUser}
              />
            )}

            {sellersNotInEvent.length === 0 && (
              <IconInfoCard
                title="No hay más vendedores en esta organización"
                info="Debes agregar vendedores a la organización para poder agregarlos a este evento"
                icon={FaUserAltSlash}
                iconColor="text-indigo-600"
              />
            )}
          </div>
        </CustomModal>
      </div>
    </div>
  );
};

export default AdminSellersPage;
